<template>
    <div id="interactiveProcess">
        <el-dialog title="Complete your listing" :visible.sync="dialogTableVisible" :closeOnClickModal="false">
            <div class="item">
                <div class="item-view1">
                    <div class="item-view1-left">Item</div>
                    <div class="item-view1-right">subtotal</div>
                </div>
                <div class="item-view2">
                    <div class="item-view2-left">
                        <div class="item-view2-left-img"><img :src="itemData.awsurl?itemData.awsurl:itemData.ipfsurl"></div>
                        <div class="item-view2-left-content">
                            <div class="item-view2-left-content-id">{{itemData.title}}</div>
                            <div class="item-view2-left-content-text">{{itemData.description}}</div>
                        </div>
                    </div>
                    <div class="item-view2-right">
                        <div class="item-view2-right-logo"><img :src="chainImg[chainId]"></div>
                        <div class="item-view2-right-price">{{itemData.price}}</div>
                    </div>
                </div>
                <div class="stepView">
                    <div class="stepView-title">Address Interaction Steps</div>
                    <div class="stepView-li" v-for="(item,index) in stepList" :key="index">
                        <div class="stepView-li-name">{{index+1}}. {{item.name}}</div>
                        <div class="stepView-li-img"><img v-if="item.state===true" src="../assets/images/success.png"><i v-else class="el-icon-loading" /></div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { chainImg } from "../web3/web3Config";
export default {
    name: "interactiveProcess",
    data() {
        return {
            chainImg: chainImg,
            dialogTableVisible: false,
            itemData: {},
            stepList: [],
        }
    },
    computed: {
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        }

    },
}
</script>
<style lang="scss" scoped>
.item {
    padding: 0 0.33rem 0 0.25rem;
}

.item-view1 {
    display: flex;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 0.19rem;
    color: #000000;

    .item-view1-right {
        margin-left: auto;
    }
}

.item-view2 {
    display: flex;
    margin-top: 0.16rem;

    .item-view2-left {
        display: flex;

        .item-view2-left-img {
            img {
                width: 0.6rem;
                height: 0.6rem;
            }
        }

        .item-view2-left-content {
            margin-left: 0.1rem;

            .item-view2-left-content-id {
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 0.13rem;
                color: #000000;
            }

            .item-view2-left-content-text {
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                color: #7F7F7F;
                margin-top: 0.08rem;
                width: 1.62rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .item-view2-right {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-top: 0.22rem;

        .item-view2-right-logo {
            margin-right: 0.06rem;

            img {
                object-fit: cover;
                width: 0.098rem;
                height: 0.16rem;
            }
        }

        .item-view2-right-price {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 0.13rem;
            color: #000000;
        }
    }
}

.stepView {
    margin-top: 0.4rem;

    .stepView-title {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 0.19rem;
        color: #000000;
    }

    .stepView-li {
        margin-top: 0.24rem;
        margin-left: -0.09rem;
        display: flex;
        align-items: center;
        width: 3.35rem;
        height: 0.5rem;
        background: #FFE161;
        border-radius: 0.08rem;
        padding: 0 0.18rem 0 0.22rem;

        .stepView-li-name {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 0.16rem;
            color: #614F00;
        }

        .stepView-li-img {
            margin-left: auto;

            img {
                width: 0.2rem;
                height: 0.2rem;
            }

            i {
                animation: rotating 2s linear infinite;
                color: #fff;
                font-size: 0.2rem;
            }
        }
    }
}

#interactiveProcess ::v-deep .el-dialog__header {
    display: flex;
    padding: 0.43rem 0.3rem 0.32rem 0.75rem;
}

#interactiveProcess ::v-deep .el-dialog {
    width: 4.18rem;
    max-height: 6rem;
    overflow: auto;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
    border-radius: 0.24rem;
}

#interactiveProcess ::v-deep .el-dialog__title {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 0.24rem;
    text-align: center;
    color: #000000;
}

#interactiveProcess ::v-deep .el-dialog__headerbtn {
    top: 0.44rem;
    right: 0.4rem;
}

#interactiveProcess ::v-deep .el-dialog__headerbtn i {
    font-size: 25px;
    font-weight: bold;
    color: #000000;
}
</style>